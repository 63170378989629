import React from 'react';
import loadable from '@loadable/component';
import '../style/style.css'
import { Image, Container } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import { flattenToAppURL } from '@plone/volto/helpers';

const View = (props) => {
  const { data } = props;
  return (
    <div className='full-width grey'>
      <Container>
        <div className='grid-container homepage-grid'>
          <div className='grid-item'>
            <Image src={'/bilder/website/im-stiftung-hochschulmedizin.png' + "/@@images/image/preview"} href={"https://stiftung-hochschulmedizin.de/"} />
          </div>
          <div className='grid-item'>
            <Image src={'/bilder/website/im-carus-campus.png' + "/@@images/image/preview"} href={"https://tu-dresden.de/med/mf/studium/caruscampus/"} />
          </div>
          <div className='grid-item'>
            <p>{data.text}</p>
          </div>
          <div className='grid-item'>
            <Image src={data.gala_image[0] ? flattenToAppURL(data.gala_image[0]['@id']) + "/@@images/image/large" : ""} href={data.gala_url ? data.gala_url : ""} />
          </div>

        </div>
        <p className='footer'>
          <Link to="/impressum">
            IMPRESSUM
          </Link>
          | KONTAKT: info@hochschulmedizin-dresden.de, 0351 458-4515 | © 2023
        </p>
      </Container>
    </div>
  );

};
export default View;