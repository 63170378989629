import React from 'react';
import { useIntl, defineMessages, useRef } from 'react-intl';
import { getTeaserImageURL } from '../helpers';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon, MaybeWrap, UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';


const DefaultImage = (props) => <img {...props} alt={props.alt || ''} />;

const windowSize = 100;

const SliderBody = ({
  data,
  isEditMode,
  text,
  disclaimer,
  farbe,
  name_autor,
  titel_autor
}) => {
  const intl = useIntl();
  const href = data.href?.[0];
  const image = data.preview_image?.[0];

  const hasImageComponent = config.getComponent('Image').component;
  const Image = config.getComponent('Image').component || DefaultImage;
  const defaultImageSrc =
    href && flattenToAppURL(getTeaserImageURL({ href, image }));
  console.log(data)
  console.log(name_autor)
  return (
    <div className="teaser-item top">
      <MaybeWrap
        condition={!isEditMode}
        as={UniversalLink}
        href=""
        target={data.openLinkInNewTab ? '_blank' : null}
        tabIndex="-1"
      >
        {(href?.hasPreviewImage || image) && (
          <div className="highlight-image-wrapper gradient slide-container">
            <Image
              src={defaultImageSrc}
              alt=""
              loading="lazy"
            />
            <div className='caption'>
              <p>{text}</p>
              <p><b>{name_autor}</b></p>
              <p>{titel_autor}</p>
            </div>
            <div className='disclaimer-wrapper'>
              <div className='disclaimer' style={farbe ? { backgroundColor: farbe } : null}>{disclaimer}</div>
            </div>
          </div>
        )}
      </MaybeWrap>
    </div>

  );
};

export default SliderBody;
