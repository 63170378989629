import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, Component } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

const ImageGrid = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  //console.log(items);
  return (
    <>
      <div className='grid-container gala-grid'>

        {items.map((item, index) => (
          <div key={index} className='grid-item gala-grid-item' onClick={() => openImageViewer(index)}>
            <img
              src={item['@id'] + "/@@images/image/preview"}

              width="300"
              key={index}
              style={{ margin: '2px' }}
              alt=""
            />
          </div>
        ))}
      </div>
      {link && <div className="footer">{link}</div>}
      <p style={{ color: 'black' }}
      ><em>Fotos: André Wirsig</em></p>

    </>
  );
};

ImageGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default ImageGrid;
